import React from 'react'
import {Router, Route} from "react-router-dom"
import Users from './users'
import Header from './header'
import User from './user'
import Account from './account'
import {useHistory} from '../context/history-context'

function App() {
    const history = useHistory()

    return <Router history={history}>
        <Header/>
        <section className="section">
            <div className="container">
                <Route path="/" exact component={Users}/>
                <Route path="/user/:userId" component={User}/>
                <Route path="/account/:accountId" component={Account} />
            </div>
        </section>
    </Router>
}

export default App