import Axios from 'axios'

const url = process.env.NODE_ENV === 'production' ? 'https://api-sandbox.unit-finance.com' : 'http://localhost:3000'

function getHeaders() {
    const headers = {
        'Content-Type': 'application/vnd.api+json',
        'Accept': 'application/vnd.api+json'
    }

    const token = window.localStorage.getItem('__unit_token__')
    if (token)
        return Object.assign({}, {'Authorization': "Bearer " + token}, headers)

    return headers
}

export async function createToken(email, password) {
    const response = await Axios.post(`${url}/user/token`, {
        data: {
            type: 'credentials',
            attributes: {
                email,
                password
            }
        }
    }, {headers: getHeaders()})

    return response.data
}

export async function getUsers() {
    // TODO: support paging
    const response = await Axios.get(`${url}/user`, {headers: getHeaders()})
    return response.data
}

export async function getUser(userId, include) {
    const query = include ? `?include=${include}` : ''

    const response = await Axios.get(`${url}/user/${userId}${query}`, {headers: getHeaders()})
    return response.data
}

export async function getAccount(accountId, include) {
    const query = include ? `?include=${include}` : ''

    const response = await Axios.get(`${url}/account/${accountId}${query}`, {headers: getHeaders()})
    return response.data
}

export async function getCounterparties(userId) {
    const response = await Axios.get(`${url}/user/${userId}/counterparty`, {headers: getHeaders()})
    return response.data
}

export async function createPayment(accountId, payment) {
    const response = await Axios.post(`${url}/account/${accountId}/payment`,  {data:payment},{headers: getHeaders()})
    return response.data
}