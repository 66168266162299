import React, {useEffect, useState} from 'react'
import classnames from 'classnames'
import {toNumber} from 'lodash'
import {createPayment, getCounterparties} from '../api'
import Conditional from './conditional'

function NewPayment({modalState, accountId, userId}) {
    const [status, setStatus] = useState('new')
    const [error, setError] = useState()

    const [counterparties, setCounterparties] = useState()
    const [counterpartyId, setCounterpartyId] = useState('')
    const [amount, setAmount] = useState(0)
    const [direction, setDirection] = useState('credit')
    const [description, setDescription] = useState('')

    const counterparty = counterparties && counterpartyId && counterparties.find(c => c.id === counterpartyId)

    // Reload counterparties when form load
    useEffect(() => {
        async function fetch() {
            const response = await getCounterparties(userId)
            setCounterparties(response.data)
        }

        if (modalState.isOpen)
            fetch()
    }, [modalState.isOpen])

    // Reset direction to credit whenever counterparty changed
    useEffect(() => setDirection('credit'), [counterpartyId])

    // Reset fields when status set to new
    useEffect(() => {
        if (status === 'new') {
            setCounterpartyId('')
            setDirection('credit')
            setAmount(0)
            setDescription('')
        }
    }, [status])

    const classname = classnames('modal', {'is-active': modalState.isOpen})
    const counterpartyClassname = classnames('select', {'is-loading': !counterparties})

    function close() {
        setStatus('new')
        modalState.close()
    }

    return (
        <div className={classname}>
            <div className="modal-background"></div>
            <div className="modal-card">
                <header className="modal-card-head">
                    <p className="modal-card-title">Create Transaction</p>
                </header>
                <section className="modal-card-body">
                    <fieldset disabled={status !== 'new'}>
                        <div className="field">
                            <label className="label">Counterparty</label>
                            <div className="control">
                                <div className={counterpartyClassname}>
                                    <select required value={counterpartyId}
                                            onChange={e => setCounterpartyId(e.target.value)}>
                                        <option hidden value="" disabled>Select counterparty</option>
                                        {counterparties
                                            ? counterparties.map(c => <option key={c.id}
                                                                              value={c.id}>{c.attributes.label + ' | ' + c.attributes.name}</option>)
                                            : null
                                        }
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="field">
                            <label className="label">Type</label>
                            <div className="control">
                                <input readOnly disabled className="input"
                                       value={counterparty ? counterparty.attributes.type : ''}/>
                            </div>
                        </div>
                        <Conditional condition={counterparty && counterparty.attributes.type === 'ach'}>
                            <div className="field">
                                <label className="label">Direction</label>
                                <div className="control">
                                    <label className="radio">
                                        <input type="radio" name="direction" checked={direction === 'credit'}
                                               onChange={() => setDirection('credit')}/>Credit
                                    </label>
                                    <label className="radio">
                                        <input type="radio" name="direction" checked={direction === 'debit'}
                                               onChange={() => setDirection('debit')}/>Debit
                                    </label>
                                </div>
                            </div>
                        </Conditional>
                        <div className="field">
                            <label className="label">Amount</label>
                            <div className="control">
                                <input className="input" placeholder="Enter amount" type="number" value={amount}
                                       onChange={e => setAmount(e.target.value)}/>
                            </div>
                        </div>
                        <div className="field">
                            <label className="label">Description</label>
                            <div className="control">
                                <input className="input" placeholder="Enter description" type="text" value={description}
                                       onChange={e => setDescription(e.target.value)}/>
                            </div>
                        </div>
                    </fieldset>
                </section>
                <footer className="modal-card-foot">
                    <Conditional condition={status === 'new'}>
                        <button className="button is-success" onClick={async () => {

                            setStatus('in-progress')

                            const payment = {
                                type: 'payment',
                                attributes: {
                                    amount: toNumber(amount),
                                    direction,
                                    description,
                                    type: counterparty.attributes.type,
                                    counterparty: {
                                        id: counterparty.id
                                    }
                                }
                            }

                            try {
                                await createPayment(accountId, payment)
                                setStatus('done')
                            } catch (e) {
                                console.log(e.response)
                                setStatus('error')
                                setError(e.message)
                            }
                        }}>Send
                        </button>
                        <button className="button" onClick={close}>Cancel</button>
                    </Conditional>
                    <Conditional condition={status === 'in-progress'}>
                        <progress className="progress is-small is-primary" max="100">15%</progress>
                    </Conditional>
                    <Conditional condition={status === 'error'}>
                        <div>
                            <article className="message is-danger">
                                <div className="message-body">
                                    {error}
                                </div>
                            </article>
                            <button className="button" onClick={close}>Close</button>
                        </div>
                    </Conditional>
                    <Conditional condition={status === 'done'}>
                        <div>
                            <article className="message is-success">
                                <div className="message-body">
                                    Sent
                                </div>
                            </article>
                            <button className="button" onClick={close}>Close</button>
                        </div>
                    </Conditional>
                </footer>
            </div>
        </div>
    )
}

export default NewPayment