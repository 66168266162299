import "core-js/stable"
import "regenerator-runtime/runtime"
import './style.scss'

import React from 'react'
import ReactDOM from 'react-dom'
import {BrowserRouter, Route, Link} from "react-router-dom"

import {TokenProvider, useToken} from './context/token-context'
import Login from './components/login'
import App from './components/app'
import {HistoryProvider} from './context/history-context'

function Root() {
    const {token} = useToken()
    if (token)
        return <App/>
    else
        return <Login/>
}

ReactDOM.render(<TokenProvider><HistoryProvider><Root/></HistoryProvider></TokenProvider>, document.getElementById('app'))