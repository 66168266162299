import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import {Link} from 'react-router-dom'
import moment from 'moment'
import {getUsers} from '../api'

function User(props) {
    const {user} = props

    return <tr>
        <th>
            <Link to={`/user/${user.id}`}>
                {user.attributes.name}
            </Link>
        </th>
        <td>
            {user.attributes.email}
        </td>
        <td>
            {user.attributes.status}
        </td>
        <td>
            {moment(user.attributes.createdAt).format('MMMM Do YYYY')}
        </td>
    </tr>
}

User.propTypes = {
    user: PropTypes.object.isRequired
}

function Users() {
    // TODO: use react-async, to support loading
    const [users, setUsers] = useState([])

    useEffect(() => {
        async function fetch() {
            const response = await getUsers()
            setUsers(response.data)
        }

        fetch()
    }, [])

    return (
        <table className="table is-striped is-hoverable is-fullwidth">
            <thead>
            <tr>
                <td>Name</td>
                <td>Email</td>
                <td>Status</td>
                <td>Created At</td>
            </tr>
            </thead>
            <tbody>
            {users.map(u => <User key={u.id} user={u}/>)}
            </tbody>
        </table>
    )
}

export default Users