import React from 'react'

import Logo from '../assets/logo.png'
import {useToken} from '../context/token-context'

function Header() {

    const {token, logout} = useToken()

    return (<div>
        <nav className="navbar is-fixed-top">
            <div className="navbar-brand">
                <a className="navbar-item" href="/">
                    <img src={Logo} />
                </a>
            </div>

            {token ?
                (<div className="navbar-end">
                    <div className="navbar-item">
                        <div className="buttons">
                            <button className="button is-light" onClick={() => logout()}>
                                Log out
                            </button>
                        </div>
                    </div>
                </div>) : null
            }
        </nav>
        <div className="hero is-primary">
            <div className="hero-body has-text-centered">
                <div className="container">
                    <h1 className="title">
                        Unit Backoffice
                    </h1>
                </div>
            </div>
        </div>
    </div>)
}

export default Header