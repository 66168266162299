import {useState} from 'react'

export default function useModal(initialState) {
    const [isOpen, setIsOpen] = useState(initialState)

    function close() {
        setIsOpen(false)
    }

    function open() {
        setIsOpen(true)
    }

    return {isOpen, open, close}
}