import React, {createContext, useState, useEffect, useContext} from 'react'
import {createBrowserHistory as createHistory} from "history"

const HistoryContext = createContext()

export function HistoryProvider(props) {
    const [history, setHistory] = useState()

    useEffect(() => setHistory(createHistory(props)), [])

    if (!history)
        return null

    return (
        <HistoryContext.Provider value={history} children={props.children} />
    )
}

export function useHistory() {
    return useContext(HistoryContext)
}