import React, {useState} from 'react'

const TokenContext = React.createContext()

const storageToken = localStorage.getItem('__unit_token__')

export function TokenProvider(props) {
    const [token, setToken] = useState(storageToken)

    function setTokenWithStorage(token) {
        setToken(token)
        localStorage.setItem('__unit_token__', token)
    }

    function logout() {
        setToken(undefined)
        localStorage.removeItem('__unit_token__')
    }

    return <TokenContext.Provider value={{token, logout, setToken: setTokenWithStorage}} {...props} />
}

export function useToken() {
    return React.useContext(TokenContext)
}