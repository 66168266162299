import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import {getUser} from '../api'
import {useHistory} from '../context/history-context'
import {Link} from 'react-router-dom'
import UserPersonalInfo from './user-personal-info'

function Account({account}) {
    return <tr>
        <th>
            <Link to={`/account/${account.id}`}>
            {account.attributes.label}
            </Link>
        </th>
        <td>{moment(account.attributes.createdAt).format('MMMM Do YYYY')}</td>
        <td>{account.attributes.type}</td>
        <td>${account.attributes.balance.usd}</td>
    </tr>
}

Account.propTypes = {
    account: PropTypes.object.isRequired
}

function User({match}) {
    // TODO: use react-async, to support loading
    const [user, setUser] = useState()
    const [accounts, setAccounts] = useState()

    useEffect(() => {
        async function fetch() {
            const response = await getUser(match.params.userId, 'accounts')
            setUser(response.data)
            setAccounts(response.included)
        }

        fetch()
    }, [])

    const history = useHistory()

    if (!user || !accounts)
        return <div>Loading...</div>

    return (
        <div>
            <div className="buttons">
                <button className="button" onClick={() => history.goBack()}>
                    <span className="icon">
                        <i className="fas fa-arrow-left"></i>
                    </span>
                    <span>Back</span>
                </button>
            </div>
            <h2 className="title">
                {user.attributes.name}
            </h2>
            <div className="columns">
                <div className="column is-narrow">
                    <UserPersonalInfo user={user} />
                </div>
                <div className="column">
                    <div className="card">
                        <div className="card-header">
                            <div className="card-header-title">
                                Accounts
                            </div>
                        </div>
                        <div className="card-content">
                            <table className="table is-striped is-hoverable is-fullwidth">
                                <thead>
                                <tr>
                                    <td>
                                        Label
                                    </td>
                                    <td>
                                        Created At
                                    </td>
                                    <td>
                                        Type
                                    </td>
                                    <td>
                                        Balance
                                    </td>
                                </tr>
                                </thead>
                                <tbody>
                                {accounts.map(a => <Account key={a.id} account={a}/>)}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

        </div>)

}

export default User