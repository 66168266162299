import React, {useState} from 'react'
import PropTypes from 'prop-types'
import {createToken} from '../api'
import {useToken} from '../context/token-context'
import Header from './header'

function Login(props) {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    const {setToken} = useToken()

    return (
        <div>
            <Header/>
            <section className="section">
                <div className="container">
                    <div className="columns is-centered">
                        <div className="column is-half">
                            <div className="field">
                                <div className="label">Email</div>
                                <div className="control has-icons-left">
                                    <input className="input" value={email} onChange={e => setEmail(e.target.value)} placeholder="Email" type="email"/>
                                    <span className="icon is-small is-left">
                                      <i className="fas fa-envelope"/>
                                    </span>
                                </div>
                            </div>
                            <div className="field">
                                <div className="label">Password</div>
                                <div className="control has-icons-left">
                                    <input className="input" value={password} onChange={e => setPassword(e.target.value)} placeholder="Password" type="password"/>
                                    <span className="icon is-small is-left">
                                      <i className="fas fa-lock"/>
                                    </span>
                                </div>
                            </div>
                            <div className="field">
                                <div className="control">
                                    <button type="primary" className="button is-primary" onClick={async () => {
                                        const response = await createToken(email, password)
                                        setToken(response.data.attributes.token)

                                        // TODO: handle error
                                    }}>Login</button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
        </div>
    )
}

export default Login