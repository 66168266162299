import React, {useEffect, useState} from 'react'
import {getAccount} from '../api'
import UserPersonalInfo from './user-personal-info'
import {toPairs} from 'lodash'
import moment from 'moment'
import {useHistory} from '../context/history-context'
import NewPayment from './new-payment'
import useModal from '../hooks/modal-hook'

function Balance({currency, amount}) {
    return <tr>
        <th className="is-uppercase">{currency}</th>
        <td>{amount}</td>
    </tr>
}

function Transaction({transaction}) {
    return <tr>
        <td>
            {transaction.id}
        </td>
        <td>
            {transaction.attributes.counterparty.name}
        </td>
        <td>
            {transaction.attributes.amount}
        </td>
        <td>
            {moment(transaction.attributes.createdAt).format('MMMM Do YYYY h:mm A')}
        </td>
        <td>
            {transaction.attributes.type}
        </td>
        <td>
            {transaction.attributes.status}
        </td>
    </tr>
}

function Account({match}) {
    const [user, setUser] = useState()
    const [account, setAccount] = useState()
    const [transactions, setTransactions] = useState()
    const newPaymentModalState = useModal(false)

    // Reload after new-payment closed
    useEffect(() => {
        async function fetch() {
            const response = await getAccount(match.params.accountId, 'user,transaction')
            setAccount(response.data)
            setUser(response.included.find(i => i.type === 'user'))
            setTransactions(response.included.filter(i => i.type === 'transaction'))
        }

        fetch()
    }, [newPaymentModalState.isOpen])

    const history = useHistory()

    if (!user || !account || !transactions)
        return <div>Loading...</div>

    return (
        <div>
            <NewPayment modalState={newPaymentModalState} accountId={account.id} userId={account.attributes.userId} />
            <div className="buttons">
                <button className="button" onClick={() => history.goBack()}>
                    <span className="icon">
                        <i className="fas fa-arrow-left"></i>
                    </span>
                    <span>Back</span>
                </button>
            </div>
            <h2 className="title">
                {user.attributes.name} | {account.attributes.label}
            </h2>
            <div className="buttons">
                <button className="button is-info" onClick={() => newPaymentModalState.open()}>New payment</button>
            </div>
            <div className="columns">
                <div className="column is-narrow">
                    <div className="card" style={{marginBottom: '1.5rem'}}>
                        <div className="card-header">
                            <div className="card-header-title">
                                Account
                            </div>
                        </div>
                        <div className="card-content">
                            <table className="table">
                                <tbody>
                                <tr>
                                    <th>Type</th>
                                    <td>{account.attributes.type}</td>
                                </tr>
                                <tr>
                                    <th>Created At</th>
                                    <td>{moment(account.attributes.createdAt).format('MMMM Do YYYY')}</td>
                                </tr>
                                {toPairs(account.attributes.balance).map(([currency, amount]) => <Balance key={currency}
                                                                                                          currency={currency}
                                                                                                          amount={amount}/>)}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <UserPersonalInfo user={user}/>
                </div>
                <div className="column">
                    <div className="card">
                        <div className="card-header">
                            <div className="card-header-title">
                                Transactions
                            </div>
                        </div>
                        <div className="card-content">
                            <table className="table is-striped is-hoverable is-fullwidth">
                                <thead>
                                <tr>
                                    <td>#</td>
                                    <td>Counterparty</td>
                                    <td>Amount</td>
                                    <td>Created At</td>
                                    <td>Type</td>
                                    <td>Status</td>
                                </tr>
                                </thead>
                                <tbody>
                                {transactions.map(t => <Transaction key={t.id} transaction={t} />)}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Account