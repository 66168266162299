import React from 'react'
import PropTypes from 'prop-types'

function UserPersonalInfo({user}) {
    const {address} = user.attributes

    return (
        <div className="card">
            <div className="card-header">
                <div className="card-header-title">
                    Personal Information
                </div>
            </div>
            <div className="card-content">
                <table className="table">
                    <tbody>
                    <tr>
                        <th>Status:</th>
                        <td>{user.attributes.status}</td>
                    </tr>
                    <tr>
                        <th>Email:</th>
                        <td>{user.attributes.email}</td>
                    </tr>
                    <tr>
                        <th>Phone:</th>
                        <td>
                            {user.attributes.phone}
                        </td>
                    </tr>
                    <tr>
                        <th>Address:</th>
                        <td>
                            <div>{address.street}</div>
                            {address.street2 ? <div>{address.street2}</div> : null}
                            <div>{address.city}, {address.state} {address.postalCode}</div>
                            {address.country ? <div>{address.country}</div> : null}
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}

UserPersonalInfo.propTypes = {
    user: PropTypes.object.isRequired
}

export default UserPersonalInfo